<template>
    <div class="animated fadeIn">
        <!-- Basic -->
        <b-card-header>
          Basic Information
          <div class="card-header-actions">
            <a class="card-header-action" href="partner-voucher" rel="noreferrer noopener">
              <small class="text-muted">Back</small>
            </a>
          </div>
        </b-card-header>
        <b-card>
            <b-row>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Name</p>
                    <p><strong>{{ this.data.name || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Code</p>
                    <p><strong>{{ this.data.code || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Prefix</p>
                    <p><strong>{{ this.data.prefix || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-4" sm="4">
                    <p class="mb-1">Voucher Amount</p>
                    <p><strong>{{ this.data.amount || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-4" sm="4">
                    <p class="mb-1">Generated By</p>
                    <p><strong>{{ `${this.data.partner_name}, ${this.data.created_at}` || '-' }}</strong></p>
                </b-col>
            </b-row>
        </b-card>
        <!-- Detail -->
        <b-card-header>
          Detail Information
        </b-card-header>
        <b-card>
            <b-row>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Created Date</p>
                    <p><strong>{{ this.data.created_at || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Start Time</p>
                    <p><strong>{{ this.data.start_time || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Expired Time</p>
                    <p><strong>{{ this.data.expired_time || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-4" sm="4">
                    <p class="mb-1">Delivery Time</p>
                    <p><strong>{{ this.data.delivery_time || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-4" sm="4">
                    <p class="mb-1">Phone Number</p>
                    <p><strong>{{ this.data.phone_number || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-4" sm="4">
                    <p class="mb-1">Delivery Status</p>
                    <p :class="this.data.delivery_status == 'Failed' ? 'text-danger' : 'text-success'" >{{ this.data.delivery_status }}</p>
                </b-col>
                <b-col class="mb-4" sm="4">
                    <p class="mb-1">Redeem Status</p>
                    <p><strong>{{ this.data.redeem_status || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-4" sm="4">
                    <p class="mb-1">Redeem Time</p>
                    <p><strong>{{ this.data.redeem_time || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Availability</p>
                    <p class="text-success" v-if="this.data.availability === 'Available'"><strong>{{ this.data.availability }}</strong></p>
                    <p class="text-danger" v-else-if="this.data.availability === 'Not Available'"><strong>{{ this.data.availability }}</strong></p>
                    <p v-else><strong>-</strong></p>
                </b-col>
                <b-col class="mb-4" sm="4">
                    <p class="mb-1">SMS Message</p>
                    <p><strong>{{ this.data.message || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-4" sm="4">
                    <p class="mb-1">Partner Invoice Number</p>
                    <p><strong>{{ this.data.partner_invoice_number || '-' }}</strong></p>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body>
            <b-card-header>
                Delivery History
            </b-card-header>
            <b-card-body>
                <div>
                    <vuetable
                        ref="vuetable"
                        :api-mode="false"
                        :data="data.delivery_history"
                        :fields="fields"
                    >
                        <template slot="attempt-slot" slot-scope="prop">
                            <span>{{ prop.rowData.attempt_number }}</span>
                        </template>
                        <template slot="phone-number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.phone_number }}</span>
                        </template>
                        <template slot="delivery-status-slot" slot-scope="prop">
                            <span :class="prop.rowData.delivery_status == 'Failed' ? 'text-danger' : 'text-success'">{{ prop.rowData.delivery_status }}</span>
                        </template>
                        <template slot="failed-reason-slot" slot-scope="prop">
                            <span>{{ prop.rowData.failed_reason }}</span>
                        </template>
                    </vuetable>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
  import axios from 'axios'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'

  Vue.use(Vuetable)

  export default {
    components:{
        Vuetable
    },
    data() {
        return {
            data: {},
            fields: [
                {
                    name: 'attempt-slot',
                    sortField: 'attempt_number',
                    title: 'Attempt Number'
                },
                {
                    name: 'phone-number-slot',
                    sortField: 'phone_number',
                    title: 'Phone Number'
                },
                {
                    name: 'delivery-status-slot',
                    sortField: 'delivery_status',
                    title: 'Delivery Status'
                },
                {
                    name: 'failed-reason-slot',
                    sortField: 'failed_reason',
                    title: 'Failed Reason'
                },
            ]
        }
    },
    created() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
        this.$http.get(`voucher/partner/details?hashed_id=${atob(this.$route.params.id)}`)
            .then((result) => {
                this.data = result.data.data;
            }).catch((error) => {
                if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                }
            })
    }
  }
</script>
